import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import PageHeading from "../../components/page_heading";
import { BlogThumbnail } from "../../components/pages/blog";

const ProjectsPage= ({ data }) => {
  const nodes = data.allFile.nodes;
  const nodeElems = nodes.map((node, index) => {
        const frontmatter = node.childMdx.frontmatter;
        const tags = frontmatter.tags ? frontmatter.tags.split(" ") : undefined;
        const url = `/projects/${node.childMdx.slug}`;
        let gatsbyImageData = undefined;
        if (frontmatter.myimage) {
          gatsbyImageData = frontmatter.myimage.childImageSharp.gatsbyImageData;
        }

        data = {
          title: frontmatter.title,
          gatsbyImageData: gatsbyImageData,
          description: frontmatter.description,
          date: frontmatter.date,
          tags: tags,
          url: url,
        };

        return <BlogThumbnail data={data} key={index} />;
      })
  return (
    <Layout heroSrc={undefined} iconName="projects">
      <PageHeading
        headingText="Personal Projects"
        subHeadingText="Made for uni courses or during my spare time"
        style={{marginBottom:"3em"}}
      />
      {nodeElems}
    </Layout>
  );
};

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "projects" }
        name: {}
        extension: { eq: "mdx" }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            myimage {
              childImageSharp {
                gatsbyImageData
              }
            }
            tags
            description
          }
          id
          slug
        }
      }
    }
  }
`;

export default ProjectsPage;


